export const root = '/blueprints-groups'
export const createGroup = `${root}`
export const getBlueprintGroup = `${root}/one/:groupId`
export const deleteBlueprintGroup = `${root}/one/:groupId`
export const listBlueprintGroup = `${root}`
export const search = `${root}/search`
export const submit = `${root}/one/:groupId/submit`
export const editBlueprint = `${root}/one/:groupId`
export const getFieldValue = `${root}/id-value/:fieldType`
export const triggerSpreadsheetExport = `${root}/export-spreadsheet`
